import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "../../components/layout"
import Helmet from "react-helmet"

const Company = () => (
  <>
    <Helmet>
      <title>WILLBUD | Polityka prywatności</title>
    </Helmet>
    <Layout>
      <PrivacyWrapper>
        <Container>
          <Row>
            <Col>
              <h1 className={`title`}>Polityka prywatności</h1>
              <ol>
                <li>
                  Administratorem Pani/Pana danych osobowych jest WILLBUD
                  M.WILLA S.K.A. ul. 10 Lutego 16, 81-364 Gdynia, KRS: 0001024217
                  NIP: 5862327977 REGON: 36950634
                </li>

                <li>
                  Kontakt z Inspektorem Ochrony Danych -{" "}
                  <a href="mailto:iod@willbud.pl">iod@willbud.pl</a>
                </li>
                <li>
                  Pani/Pana dane osobowe przetwarzane będą w celach
                  marketingowych na podstawie Art. 6 ust. 1 lit. a ogólnego
                  rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia
                  2016 r.
                </li>
                <li>
                  Posiada Pani/Pan prawo do żądania od administratora dostępu do
                  danych osobowych, prawo do ich sprostowania, usunięcia lub
                  ograniczenia przetwarzania, prawo do cofnięcia zgody oraz
                  prawo do przenoszenia danych
                </li>
                <li>
                  Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego
                </li>
                <li>
                  Podanie danych osobowych jest dobrowolne, jednakże niepodanie
                  danych może skutkować niemożliwością uczestnictwa w
                  subskrypcji newslettera, otrzymania ofert marketingowych i
                  udziału w ankietach
                </li>
                <li>
                  Jednocześnie zgodnie z art. 6 ust. 1 lit. a ogólnego
                  rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia
                  2016 r wyrażam zgodę na przetwarzanie moich danych osobowych w
                  celu otrzymania ofert marketingowych
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </PrivacyWrapper>
    </Layout>
  </>
)

export default Company

export const PrivacyWrapper = styled.div`
  margin-bottom: 100px;
  padding-top: 0;
  font-size: 20px;
  font-weight: 200;

  .title {
    font-size: 37px;
    margin-bottom: 20px;
  }

  ol {
    padding-left: 20px;
    li {
      padding-left: 10px;
      margin-bottom: 20px;
    }
  }

  @media (min-width: 1200px) {
    padding-top: 59px;
    .title {
      font-size: 48px;
      margin-bottom: 40px;
    }
  }
`
